<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>
        <div class="visual-item w1200" style="display: block;">

          <div class="view" >

            <el-alert
                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"
                type="error"
                effect="dark" style="margin-bottom: 20px" >
            </el-alert>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[10].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[10].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[11].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[11].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[12].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[12].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[13].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[13].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[14].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[14].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[15].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[15].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[16].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[16].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[17].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[17].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[18].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[18].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[19].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[19].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[20].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[20].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[21].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[21].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[22].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[22].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[23].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[23].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[24].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[24].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[25].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[25].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[26].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[26].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[27].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[27].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[28].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[28].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[29].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[29].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[30].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[30].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[31].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[31].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[32].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[32].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[33].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[33].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[34].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[34].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[35].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[35].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[36].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[36].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[37].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[37].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[38].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[38].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[39].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[39].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[40].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[40].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[41].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[41].url"></div>
                  </router-link>
                </div>

<!--                <div class="rec-store-item opacity_img">-->
<!--                  <router-link :to="{path:'/goods',query:{id:Floor[42].id}}" target="_blank"  tag="a">-->
<!--                    <div class="p-img"><img :src="Floor[42].url"></div>-->
<!--                  </router-link>-->
<!--                </div>-->

<!--                <div class="rec-store-item opacity_img">-->
<!--                  <router-link :to="{path:'/goods',query:{id:Floor[43].id}}" target="_blank"  tag="a">-->
<!--                    <div class="p-img"><img :src="Floor[43].url"></div>-->
<!--                  </router-link>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "Promotion",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[
        {'index':0,'url':'http://image.yabyy.com/home/new/1033464.png','id':'15642','search':''},     //15642	1033464	益气养血口服液
        {'index':1,'url':'http://image.yabyy.com/home/new/1033465.png','id':'15643','search':''},      //15643	1033465	脑心舒口服液
        {'index':2,'url':'http://image.yabyy.com/home/new/1033466.png','id':'15644','search':''},      //15644	1033466	玉屏风口服液
        {'index':3,'url':'http://image.yabyy.com/home/new/1033467.png','id':'15645','search':''},      //15645	1033467	生脉饮(党参方)

        {'index':4,'url':'http://image.yabyy.com/home/new/1004005.png','id':'7108','search':''},     //7108	1004005	苯磺酸左氨氯地平片
        {'index':5,'url':'http://image.yabyy.com/home/new/1033816.png','id':'16571','search':''},      //16571	1033816	黄芪片
        {'index':6,'url':'http://image.yabyy.com/home/new/1004541.png','id':'7532','search':''},      //7532	1004541	盐酸左氧氟沙星滴眼液
        {'index':7,'url':'http://image.yabyy.com/home/new/1006755.png','id':'9052','search':''},      //9052	1006755	水杨酸苯酚贴膏

        {'index':8,'url':'http://image.yabyy.com/home/new/1002116.png','id':'5625','search':''},     // 5625	1002116	吲达帕胺片
        {'index':9,'url':'http://image.yabyy.com/home/new/1007019.png','id':'9231','search':''},    //9231	1007019	感冒灵胶囊
        {'index':10,'url':'http://image.yabyy.com/home/new/1007726.png','id':'9683','search':''},  //9683	1007726	头孢地尼分散片
        {'index':11,'url':'http://image.yabyy.com/home/new/1032389.png','id':'15179','search':''},//15179	1032389	六味地黄胶囊

        {'index':12,'url':'http://image.yabyy.com/home/new/1002517.png','id':'5939','search':''},       //5939	1002517	曲安奈德益康唑乳膏
        {'index':13,'url':'http://image.yabyy.com/home/new/1004590.png','id':'7577','search':''},       //7577	1004590	头孢克肟胶囊
        {'index':14,'url':'http://image.yabyy.com/home/new/1019601.png','id':'14481','search':''},      //14481	1019601	苯磺酸氨氯地平片
        {'index':15,'url':'http://image.yabyy.com/home/new/1035333.png','id':'17074','search':''},//17074	1035333	参芪健胃颗粒

        {'index':16,'url':'http://image.yabyy.com/home/new/1003920.png','id':'7047','search':''}, //7047	1003920	盐酸二甲双胍缓释片
        {'index':17,'url':'http://image.yabyy.com/home/new/1004292.png','id':'7336','search':''}, //7336	1004292	曲安奈德鼻喷雾剂
        {'index':18,'url':'http://image.yabyy.com/home/new/1004028.png','id':'7129','search':''},//7129	1004028	独一味软胶囊
        {'index':19,'url':'http://image.yabyy.com/home/new/1005079.png','id':'7962','search':''},     //7962	1005079	妇炎康片(薄膜衣片)

        {'index':20,'url':'http://image.yabyy.com/home/new/1008200.png','id':'9971','search':''}, //9971	1008200	复方氨酚烷胺胶囊
        {'index':21,'url':'http://image.yabyy.com/home/new/1008965.png','id':'10397','search':''},      //10397	1008965	咽炎片
        {'index':22,'url':'http://image.yabyy.com/home/new/1011094.png','id':'11602','search':''},      //11602	1011094	消食健胃片
        {'index':23,'url':'http://image.yabyy.com/home/new/1010273.png','id':'11169','search':''}, //11169	1010273	复方氨酚烷胺胶囊

        {'index':24,'url':'http://image.yabyy.com/home/new/1015952.png','id':'14207','search':''},//14207	1015952	黄苦洗液(内赠冲洗器)
        {'index':25,'url':'http://image.yabyy.com/home/new/1001126.png','id':'4887','search':''},//4887	1001126	川贝枇杷糖浆
        {'index':26,'url':'http://image.yabyy.com/home/new/1032054.png','id':'15040','search':''},//15040	1032054	比沙可啶肠溶片
        {'index':27,'url':'http://image.yabyy.com/home/new/1006323.png','id':'8753','search':''},//8753	1006323	厄贝沙坦分散片

        {'index':28,'url':'http://image.yabyy.com/home/new/1023083.png','id':'14561','search':''},//14561	1023083	盐酸氨溴索口服溶液
        {'index':29,'url':'http://image.yabyy.com/home/new/1032664.png','id':'15297','search':''},//15297	1032664	维生素C泡腾片
        //{'index':30,'url':'http://image.yabyy.com/home/new/1033463.png','id':'15641','search':''},//15641	1033463	便乃通茶
        {'index':30,'url':'http://image.yabyy.com/home/new/1010665.png','id':'11362','search':''},//11362	1010665	红霉素眼膏

        {'index':32,'url':'http://image.yabyy.com/home/new/1026986.png','id':'14623','search':''},//14623	1026986	生理性海水鼻腔喷雾器
        {'index':33,'url':'http://image.yabyy.com/home/new/1030330.png','id':'14706','search':''},//14706	1030330	维胺酯维E乳膏
        {'index':34,'url':'http://image.yabyy.com/home/new/1033462.png','id':'15640','search':''},//15640	1033462	复方丹参片(薄膜衣大片)
        //{'index':35,'url':'http://image.yabyy.com/home/new/1010124.png','id':'11099','search':''},//11099	1010124	六味地黄胶囊(铁盒)

        {'index':36,'url':'http://image.yabyy.com/home/new/1012347.png','id':'12259','search':''},//12259	1012347	治咳枇杷露
        {'index':37,'url':'http://image.yabyy.com/home/new/1003531.png','id':'6740','search':''},//6740	1003531	板蓝根颗粒
        {'index':38,'url':'http://image.yabyy.com/home/new/1033985.png','id':'16603','search':''},//16603	1033985	藤黄健骨丸
        {'index':39,'url':'http://image.yabyy.com/home/new/1033043.png','id':'15455','search':''},//15455	1033043	复方枇杷叶膏

        {'index':40,'url':'http://image.yabyy.com/home/new/1035180.png','id':'17181','search':''},//17181	1035180	瑞舒伐他汀钙片
        {'index':41,'url':'http://image.yabyy.com/home/new/1035332.png','id':'17277','search':''},//17277	1035332	复方酮康唑发用洗剂
        {'index':35,'url':'http://image.yabyy.com/home/new/1033464.png','id':'15642','search':''},//15642	1033464	益气养血口服液
        {'index':31,'url':'http://image.yabyy.com/home/new/1033489.png','id':'15662','search':''},//15662	1033489	银黄颗粒
      ],
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
